import { Box, Grid, MenuItem } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { useState } from 'react';
import * as React from 'react';
import PropTypes from 'prop-types';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import Typography from '../../../../../../components/Typography/';

import localesShape from '../../../../shapes/locales';

import WorldGlobe from '../../../../../../icons/WorldGlobe';

import Es from '../../../../../../icons/es';
import En from '../../../../../../icons/en';
import It from '../../../../../../icons/it';
import De from '../../../../../../icons/de';
import Fr from '../../../../../../icons/fr';
import Pt from '../../../../../../icons/pt';

import {
  StyledLanguageOption,
  StyledFlag,
  StyledRightIcon,
  StyledDrawer,
  StyledToolbar,
  StyledMenuList
} from './styles';

const flags = {
  es: <Es />,
  en: <En />,
  it: <It />,
  de: <De />,
  fr: <Fr />,
  pt: <Pt />
};

const MobileLanguageMenu = ({ locales, onLanguageChange }) => {
  const [languageMenuIsOpen, setLanguageMenuOpen] = useState(false);
  const [lang, setLang] = React.useState(locales.current);

  const handleLanguageChange = locale => {
    setLang(locale);
    onLanguageChange(locale);
    setLanguageMenuOpen(false);
  };

  const muiTheme = useTheme();

  const handleLanguageMenuClick = () => {
    setLanguageMenuOpen(!languageMenuIsOpen);
  };

  return (
    <Box sx={{ flexGrow: 0, display: { md: 'flex' } }}>
      <StyledLanguageOption onClick={handleLanguageMenuClick}>
        <WorldGlobe />
        <Typography variant="HeadingS">{lang}</Typography>
        <StyledRightIcon>
          <ChevronRightIcon
            style={{
              fontSize: '24px'
            }}
          />
        </StyledRightIcon>
      </StyledLanguageOption>

      <StyledDrawer
        anchor={'right'}
        open={languageMenuIsOpen}
        onClose={() => setLanguageMenuOpen(false)}
        theme={muiTheme}
      >
        <StyledToolbar>
          <Grid container alignItems={'center'}>
            <Box
              sx={{
                marginRight: 'auto',
                display: 'flex'
              }}
            >
              <ArrowBackIcon
                onClick={handleLanguageMenuClick}
                style={{
                  fontSize: '24px'
                }}
              />
            </Box>
            <Box
              sx={{
                marginLeft: 'auto',
                marginRight: 'auto',
                textTransform: 'capitalize'
              }}
            >
              <Typography variant="HeadingS">{lang}</Typography>
            </Box>
            <Box sx={{ marginLeft: 'auto', display: 'flex' }}>
              <CloseIcon
                onClick={handleLanguageMenuClick}
                style={{
                  fontSize: '24px'
                }}
              />
            </Box>
          </Grid>
        </StyledToolbar>
        <Box role="presentation" sx={{ width: '100vw' }}>
          <StyledMenuList>
            {Object.keys(locales.ui).map(locale => (
              <MenuItem
                sx={{
                  paddingBottom: '32px',
                  '&:hover': {
                    backgroundColor: 'transparent'
                  }
                }}
                disableRipple
                key={locale}
                onClick={() => handleLanguageChange(locale)}
              >
                <StyledFlag>{flags[locale]}</StyledFlag>
                <Box sx={{ px: 1 }}>
                  <Typography variant="HeadingS">
                    {locales.ui[locale].name}
                  </Typography>
                </Box>
              </MenuItem>
            ))}
          </StyledMenuList>
        </Box>
      </StyledDrawer>
    </Box>
  );
};

MobileLanguageMenu.propTypes = {
  locales: PropTypes.shape(localesShape).isRequired,
  onLanguageChange: PropTypes.func.isRequired
};

export default MobileLanguageMenu;
