import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

const TableButton = styled(Box)(({ plus, fullWidth }) => ({
  height: 34,
  padding: '0 24px !important',
  ...(plus && {
    '& p': { color: 'white' }
  }),
  ...(fullWidth && { width: '100%' })
}));

export default TableButton;
