import { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import NoSSR from 'react-no-ssr';

import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/styles';

import { trackLandlordLandingSectionViewed } from '../../utils/track';

import MobilePlans from './MobilePlans';
import DesktopPlans from './DesktopPlans';

import { StyledPlansSection } from './styles';

const Plans = () => {
  const muiTheme = useTheme();

  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.5
  });

  useEffect(() => {
    if (inView) {
      trackLandlordLandingSectionViewed('plans-table');
    }
  }, [inView]);

  return (
    <StyledPlansSection data-scroll="plan-section" ref={ref}>
      <NoSSR>
        {useMediaQuery(muiTheme.breakpoints.up('md')) ? (
          <DesktopPlans />
        ) : (
          <MobilePlans />
        )}
      </NoSSR>
    </StyledPlansSection>
  );
};

export default Plans;
