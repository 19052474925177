import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

export const StyledContainer = styled(Box)(({ theme }) => ({
  marginBottom: '32px',
  '&:last-child': {
    marginBottom: 0
  },
  [theme.breakpoints.up('sm')]: {
    flexBasis: 'calc((100% - 32px) / 2)'
  },
  [theme.breakpoints.up('lg')]: {
    flexBasis: 'calc((1088px - 96px) / 4)',
    marginBottom: 0
  }
}));

export const StyledList = styled('ul')(({ isInline }) => ({
  display: 'flex',
  margin: 0,
  flexDirection: isInline ? 'row' : 'column',
  listStyle: 'none',
  padding: 0,
  '& li': {
    listStyleType: 'none',
    marginBottom: '12px',
    marginRight: isInline ? '8px' : 0
  }
}));

export const StyledTitle = styled(Box)(() => ({
  marginTop: '16px'
}));
