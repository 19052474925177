import { styled, keyframes } from '@mui/material/styles';
import Box from '@mui/material/Box';

const baseSize = '10px';
const animationDuration = '1.5s';

const ledOutInRight = keyframes`
  0% {
    height: calc(2 * ${baseSize});
    width: calc(2 * ${baseSize});
    top: calc(${baseSize} / -2);
    right: calc(${baseSize} / -2);
    opacity: 0.5;
  }
  25% {
    opacity: 0;
  }
  50% {
    height: ${baseSize};
    width: ${baseSize};
    top: 0;
    right: 0;
    opacity: 1;
  }
  100% {
    height: calc(2 * ${baseSize});
    width: calc(2 * ${baseSize});
    top: calc(${baseSize} / -2);
    right: calc(${baseSize} / -2);
    opacity: 0.5;
  }
`;

const ledOutInLeft = keyframes`
  0% {
    height: calc(2 * ${baseSize});
    width: calc(2 * ${baseSize});
    top: calc(${baseSize} / -2);
    left: calc(${baseSize} / -2);
    opacity: 0.5;
  }
  25% {
    opacity: 0;
  }
  50% {
    height: ${baseSize};
    width: ${baseSize};
    top: 0;
    left: 0;
    opacity: 1;
  }
  100% {
    height: calc(2 * ${baseSize});
    width: calc(2 * ${baseSize});
    top: calc(${baseSize} / -2);
    left: calc(${baseSize} / -2);
    opacity: 0.5;
  }
`;

export const StyledRoot = styled(Box)(() => ({
  position: 'relative'
}));

export const StyledLed = styled(Box)(({ position, isHidden }) => ({
  position: 'absolute',
  top: 0,
  backgroundColor: 'var(--sah-primary)',
  display: isHidden ? 'none' : 'block',
  ...(position === 'right'
    ? {
        right: '-15px',
        '&:after, &:before': {
          right: 0
        },
        '&:before': {
          animation: `${ledOutInRight} ${animationDuration} linear infinite`
        },
        '&:after': {
          animation: `${ledOutInRight} ${animationDuration} linear calc(${animationDuration} / 2) infinite`
        }
      }
    : {
        left: 0,
        '&:after, &:before': {
          left: 0
        },
        '&:before': {
          animation: `${ledOutInLeft} ${animationDuration} linear infinite`
        },
        '&:after': {
          animation: `${ledOutInLeft} ${animationDuration} linear calc(${animationDuration} / 2) infinite`
        }
      }),
  '&:after, &:before': {
    pointerEvents: 'none',
    content: '""',
    height: baseSize,
    width: baseSize,
    borderRadius: '50%',
    position: 'absolute',
    top: 0,
    opacity: 1,
    backgroundColor: 'inherit'
  }
}));
