import { styled } from '@mui/material/styles';
import { Box, Drawer, Toolbar, MenuList } from '@mui/material';

export const StyledLanguageOption = styled(Box)(() => ({
  height: '80px',
  display: 'flex',
  alignItems: 'center',
  gap: '16px',
  textTransform: 'capitalize'
}));

export const StyledFlag = styled(Box)(() => ({
  paddingRight: '12px'
}));

export const StyledRightIcon = styled(Box)(() => ({
  marginLeft: 'auto',
  display: 'flex'
}));

export const StyledDrawer = styled(Drawer)(() => ({}));

export const StyledToolbar = styled(Toolbar)(() => ({}));

export const StyledMenuList = styled(MenuList)(() => ({}));
