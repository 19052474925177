import { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { styled } from '@mui/material/styles';
import { trans } from '@spotahome/soyuz/client';
import Typography from '@spotahome/landlord-panel-ui-library/src/components/Typography';

import {
  trackLandlordStepsToPublishCta,
  trackLandlordLandingSectionViewed
} from '../../utils/track';
import LandingButton from '../LandingButton';

import StepsToPublishAccordion from './StepsToPublishAccordion';

const Main = styled('section')(({ theme }) => ({
  display: 'flex',
  placeItems: 'center',
  flexDirection: 'column',
  marginBottom: 60,
  [theme.breakpoints.up('sm')]: {
    marginBottom: 120
  }
}));

const ContentDisplayM = styled('div')(({ theme }) => ({
  margin: 60,
  display: ' none',
  [theme.breakpoints.up('sm')]: {
    display: ' block'
  }
}));

const ContentDisplayS = styled('div')(({ theme }) => ({
  margin: 45,
  display: ' block',
  [theme.breakpoints.up('sm')]: {
    display: ' none'
  }
}));

const StepsToPublishSection = () => {
  const handleOnClickCta = () => {
    trackLandlordStepsToPublishCta();
  };

  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.5
  });

  useEffect(() => {
    if (inView) {
      trackLandlordLandingSectionViewed('steps-to-publish');
    }
  }, [inView]);

  return (
    <Main ref={ref}>
      <ContentDisplayM>
        <Typography variant="DisplayM">
          {trans('landing.landlord.section.stepsTpPublish.text2')}
        </Typography>
      </ContentDisplayM>
      <ContentDisplayS>
        <Typography variant="DisplayS">
          {trans('landing.landlord.section.stepsTpPublish.text2')}
        </Typography>
      </ContentDisplayS>
      <StepsToPublishAccordion />
      <LandingButton
        data-test="landlord-steps-to-publish-cta"
        onClick={handleOnClickCta}
      >
        {trans('landlord.landing.plus-section.cta')}
      </LandingButton>
    </Main>
  );
};

export default StepsToPublishSection;
