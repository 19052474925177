import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

import { Button } from '@spotahome/ui-library';

export const StyledHero = styled('section')(() => ({
  display: 'flex',
  height: 'auto',
  flexWrap: 'wrap',
  alignContent: 'stretch'
}));

export const StyledHeroWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  padding: '32px 24px 24px',
  flexWrap: 'wrap',
  alignItems: 'center',
  position: 'relative',
  width: '100%',
  justifyContent: 'center',
  [theme.breakpoints.up('sm')]: {
    padding: '125px 24px 24px',
    justifyContent: 'flex-end',
    width: '50%'
  }
}));

export const StyledContent = styled(Box)(() => ({
  width: '100%',
  maxWidth: '640px'
}));

export const StyledHeading = styled(Box)(() => ({
  textTransform: 'uppercase',
  marginBottom: '16px'
}));

export const StyledTitleMobile = styled(Box)(({ theme }) => ({
  display: 'block',
  margin: '0 0 16px',
  [theme.breakpoints.up('sm')]: {
    display: 'none'
  }
}));

export const StyledTitleDesktop = styled(Box)(({ theme }) => ({
  display: 'none',
  margin: '0 0 16px',
  [theme.breakpoints.up('sm')]: {
    display: 'block'
  }
}));

export const StyledSubtitle = styled('p')(() => ({
  fontSize: 'var(--sah-font-l)',
  fontFamily: 'var(--sah-font-light)',
  margin: '0',
  lineHeight: 'var(--sah-line-height-l)'
}));

export const StyledCtaStyles = styled(Button)(() => ({
  fontFamily: 'var(--sah-font-bold) !important',
  fontSize: 'var(--sah-font-m)',
  display: 'inline-block !important',
  borderRadius: '0',
  padding: '16px 32px !important',
  margin: '32px 0 24px',
  width: 'auto'
}));

export const StyledLoginLink = styled('a')(({ theme }) => ({
  fontSize: 'var(--sah-font-s)',
  lineHeight: 'var(--sah-line-height-s)',
  fontFamily: 'var(--sah-font-bold)',
  display: 'block',
  textDecoration: 'underline',
  color: 'var(--sah-interaction-dark)',
  marginBottom: 48,
  [theme.breakpoints.up('sm')]: {
    marginBottom: 80
  }
}));

export const StyledVideoWrapper = styled(Box)(({ theme }) => ({
  position: 'relative',
  display: 'none',
  overflow: 'hidden',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '0',
  backgroundClip: 'border-box',
  objectFit: 'cover',
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '50%'
  }
}));

export const StyledVideoContainer = styled(Box)(() => ({
  position: 'absolute',
  display: 'block',
  overflow: 'hidden',
  backgroundSize: 'cover',
  objectFit: 'cover',
  width: '100%',
  height: '100%'
}));

export const StyledPlayer = styled('video')(() => ({
  width: '100%',
  height: '100%',
  objectFit: 'cover'
}));

export const StyledCover = styled('img')(() => ({
  width: '100%'
}));
