import { TrustpilotReviews } from '@spotahome/ui-library';
import { trans, getPagePath, getResourcePath } from '@spotahome/soyuz/client';
import Typography from '@spotahome/landlord-panel-ui-library/src/components/Typography';

import {
  trackLandlordJoinCta,
  trackLandlordAccessCta
} from '../../utils/track';

import {
  StyledHero,
  StyledHeroWrapper,
  StyledContent,
  StyledHeading,
  StyledTitleMobile,
  StyledTitleDesktop,
  StyledSubtitle,
  StyledCtaStyles,
  StyledLoginLink,
  StyledVideoWrapper,
  StyledVideoContainer,
  StyledPlayer,
  StyledCover
} from './styles';

const HeroSection = () => {
  const sendJoinTodayGaEvent = () => {
    trackLandlordJoinCta();
  };

  const sendAccessPanelGaEvent = () => {
    trackLandlordAccessCta();
  };

  return (
    <StyledHero>
      <StyledHeroWrapper>
        <StyledContent>
          <StyledHeading>
            <Typography variant="TagL">
              {trans('lister.landing.page-header-alt')}
            </Typography>
          </StyledHeading>

          <StyledTitleMobile>
            <Typography variant="DisplayM">
              {trans('lister.landing.experiment.copies.v1.page-title')}
            </Typography>
          </StyledTitleMobile>
          <StyledTitleDesktop>
            <Typography variant="DisplayL">
              {trans('lister.landing.experiment.copies.v1.page-title')}
            </Typography>
          </StyledTitleDesktop>

          <StyledSubtitle>
            {trans('lister.landing.experiment.copies.v1.page-subtitle')}
          </StyledSubtitle>

          <StyledCtaStyles
            data-test="landlord-cta"
            size="big"
            color="primary"
            href={getPagePath('landlord.signup')}
            onClick={sendJoinTodayGaEvent}
          >
            {trans('lister.landing.experiment.copies.v1.signup')}
          </StyledCtaStyles>

          <StyledLoginLink
            href={getPagePath('landlord.login')}
            onClick={sendAccessPanelGaEvent}
          >
            {trans('lister.landing.alternative.signin')}
          </StyledLoginLink>
          <TrustpilotReviews theme="minimal" />
        </StyledContent>
      </StyledHeroWrapper>
      <StyledVideoWrapper>
        <StyledCover
          src={getResourcePath('/images/new-landlord-cover.jpg')}
          loading="lazy"
          alt={trans('lister.landing.alternative.page-title')}
        />
        <StyledVideoContainer>
          <StyledPlayer autoPlay loop playsInline muted>
            <source src={getResourcePath('/videos/landlord-landing.mp4')} />
          </StyledPlayer>
        </StyledVideoContainer>
      </StyledVideoWrapper>
    </StyledHero>
  );
};

export default HeroSection;
