import { trans, getPagePath } from '@spotahome/soyuz/client';
import Box from '@mui/material/Box';

import { trackLandlordJoinCta } from '../../utils/track';

import PlusPicture from './PlusPicture';
import {
  StyledMain,
  StyledWrapper,
  StyledCtaContent,
  StyledCta
} from './styles';

const PlusSection = () => {
  const handleOnClickCta = () => {
    trackLandlordJoinCta('bottom');
  };

  return (
    <StyledMain>
      <PlusPicture pictureId="landing-plus-section" />
      <PlusPicture pictureId="landing-plus-section-sm" isMobile />
      <StyledWrapper>
        <Box>
          <StyledCtaContent variant="DisplayL">
            {trans('landlord.landing.plus-section.title')}
          </StyledCtaContent>

          <StyledCtaContent variant="ParagraphL">
            {trans('landlord.landing.plus-section.subtitle')}
          </StyledCtaContent>

          <StyledCta
            data-test="landlord-plus-cta"
            size="big"
            color="primary"
            href={getPagePath('landlord.signup')}
            onClick={handleOnClickCta}
          >
            {trans('landlord.landing.plus-section.cta')}
          </StyledCta>
        </Box>
      </StyledWrapper>
    </StyledMain>
  );
};

export default PlusSection;
