import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { RadioGroup, Radio } from '@mui/material';

export const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: 'var(--sah-white)',
  padding: '20px',
  margin: '10px',
  borderRadius: '20px',
  flex: '1',
  maxWidth: '500px',
  minHeight: '350px',
  [theme.breakpoints.up('sm')]: {
    minHeight: '300px'
  }
}));

export const StyledIcon = styled('div')(({ theme }) => ({
  minWidth: '50px',
  width: '50px',
  textAlign: 'center',
  marginRight: '16px',
  marginBottom: '32px',
  [theme.breakpoints.up('sm')]: {
    textAlignLast: 'left'
  }
}));

export const StyledDescription = styled('div')(({ theme }) => ({
  fontSize: 'var(--sah-font-s)',
  lineHeight: '24px',
  color: 'var(--sah-dark)',
  padding: 0,
  [theme.breakpoints.up('sm')]: {
    padding: '8px 0'
  },
  fontFamily: 'var(--sah-font-title)'
}));

export const StyledTitle = styled('h3')(() => ({
  fontFamily: 'var(--sah-font-title)',
  color: 'var(--sah-dark)',
  padding: 0,
  marginTop: 0,
  fontSize: 'var(--sah-font-xxxl)',
  lineHeight: 'var(--sah-line-height-l)',
  fontWeight: 'var(--sah-font-w-light)',
  marginBottom: '16px'
}));

export const StyledBoxWrapper = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center'
}));

export const StyledSection = styled('section')(({ theme }) => ({
  width: '100%',
  padding: '48px 24px',
  display: 'flex',
  flexDirection: 'column',
  justifyItems: 'center',
  backgroundColor: 'var(--sah-tertiary)',
  [theme.breakpoints.up('sm')]: {
    padding: '(40px * 2) 48px'
  },
  [theme.breakpoints.up('lg')]: {
    padding: '120px 48px'
  }
}));

export const StyledRadioGroup = styled(RadioGroup)(() => ({
  justifyContent: 'center'
}));

export const StyledRadio = styled(Radio)(() => ({
  '&.MuiRadio-colorPrimary': {
    color: 'var(--sah-interaction-dark)',
    '&.Mui-checked': {
      color: 'var(--sah-interaction-dark)'
    }
  }
}));

export const StyledContainer = styled(Box)(() => ({
  width: '100%',
  maxWidth: '1440px',
  margin: 'auto'
}));

export const StyledCtaWrapper = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center'
}));

export const StyledSectionTitle = styled('h2')(({ theme }) => ({
  fontFamily: 'var(--sah-font-title)',
  fontWeight: 'var(--sah-font-w-light)',
  fontSize: 'var(--sah-font-xxxxl)',
  lineHeight: 'var(--sah-line-height-xxxxl)',
  margin: '0 0 40px',
  [theme.breakpoints.up('sm')]: {
    fontSize: 'var(--sah-font-xxxxxxl)',
    lineHeight: 'var(--sah-line-height-xxxxxxl)',
    margin: '0 0 48px'
  },
  [theme.breakpoints.up('lg')]: {
    margin: '0 0 88px',
    fontSize: '64px',
    lineHeight: '72px'
  }
}));

export const StyledElements = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

export const StyledCta = styled(Box)(() => ({
  fontFamily: 'var(--sah-font-title)',
  fontSize: 'var(--sah-font-xxxl)',
  margin: '25px 10px',
  display: 'flex',
  justifyContent: 'center',
  '& a': {
    minWidth: '250px'
  }
}));
