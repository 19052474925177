import { styled } from '@mui/material/styles';

import { Button } from '@spotahome/ui-library';

export const StyledButton = styled(Button)(({ theme }) => ({
  fontFamily: 'var(--sah-font-bold)',
  fontSize: 'var(--sah-font-m)',
  display: 'inline-block',
  margin: 0,
  padding: '16px 32px',
  borderRadius: 100,
  minWidth: 150,
  [theme.breakpoints.up('sm')]: {
    borderRadius: '0',
    width: 'auto',
    minWidth: 200
  },
  [theme.breakpoints.up('md')]: {
    minWidth: 320
  }
}));
