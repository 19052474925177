import { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { trans, getPagePath } from '@spotahome/soyuz/client';
import Typography from '@spotahome/landlord-panel-ui-library/src/components/Typography';

import { trackLandlordLandingSectionViewed } from '../../utils/track';

import FeatureBox from './FeatureBox';

import MobileIcon from './icons/MobileIcon';
import MoneyIcon from './icons/MoneyIcon';
import ProtectionIcon from './icons/ProtectionIcon';
import QualityIcon from './icons/QualityIcon';
import SupportIcon from './icons/SupportIcon';
import WorldIcon from './icons/WorldIcon';

import {
  StyledSection,
  StyledContainer,
  StyledSectionTitle,
  StyledGrid
} from './styles';

const getFeatureList = () => [
  {
    id: 'guaranteed.payments',
    icon: <ProtectionIcon />,
    title: trans('lister.landing.alternative.features.guaranteed-title'),
    description: trans('lister.landing.alternative.features.guaranteed-desc'),
    learnMoreLink: getPagePath('externals.plus-plan')
  },
  {
    id: 'worldwide-visibility',
    icon: <WorldIcon />,
    title: trans('lister.landing.alternative.features.worldwide-title'),
    description: trans('lister.landing.alternative.features.worldwide-desc'),
    learnMore: trans('lister.landing.alternative.features.worldwide-learn-more')
  },
  {
    id: 'quality-tenants',
    icon: <QualityIcon />,
    title: trans('lister.landing.alternative.features.quality-title'),
    description: trans('lister.landing.alternative.features.quality-desc'),
    learnMore: trans('lister.landing.alternative.features.quality-learn-more')
  },
  {
    id: 'online-bookings',
    icon: <MobileIcon />,
    title: trans('lister.landing.alternative.features.verification-title'),
    description: trans('lister.landing.alternative.features.verification-desc'),
    learnMore: trans(
      'lister.landing.alternative.features.verification-learn-more'
    )
  },
  {
    id: 'charge-once',
    icon: <MoneyIcon />,
    title: trans('lister.landing.alternative.features.fee-title'),
    description: trans('lister.landing.alternative.features.fee-desc'),
    learnMore: trans('lister.landing.alternative.features.fee-learn-more')
  },
  {
    id: 'personalized-service',
    icon: <SupportIcon />,
    title: trans('lister.landing.alternative.features.support-title'),
    description: trans('lister.landing.alternative.features.support-desc'),
    learnMore: trans('lister.landing.alternative.features.support-learn-more')
  }
];

const FeatureBoxSection = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.5
  });

  useEffect(() => {
    if (inView) {
      trackLandlordLandingSectionViewed('features');
    }
  }, [inView]);

  return (
    <StyledSection ref={ref}>
      <StyledContainer>
        <StyledSectionTitle>
          <Typography variant="DisplayM" renderAs="h2">
            {trans('lister.landing.alternative.feature-title')}
          </Typography>
        </StyledSectionTitle>

        <StyledGrid>
          {getFeatureList().map(feature => (
            <FeatureBox
              key={feature.id}
              id={feature.id}
              icon={feature.icon}
              title={feature.title}
              description={feature.description}
              learnMore={feature.learnMore}
              learnMoreLink={feature.learnMoreLink}
            />
          ))}
        </StyledGrid>
      </StyledContainer>
    </StyledSection>
  );
};

export default FeatureBoxSection;
