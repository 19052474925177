import { styled } from '@mui/material/styles';
import { Drawer, Box } from '@mui/material';

export const StyledNavigationModal = styled(Drawer)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'none'
  }
}));

export const StyledRoot = styled(Box)(() => ({
  paddingTop: '40px',
  paddingBottom: '60px'
}));

export const StyledTitle = styled(Box)(() => ({
  marginBottom: '8px'
}));

export const StyledText = styled(Box)(() => ({
  marginBottom: '16px'
}));

export const StyledButton = styled(Box)(() => ({
  marginBottom: '16px'
}));
