import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

// This should be removed after using the correct theme in every page
export const fontNormalizer = {
  '--sah-font-s': '14px',
  '--sah-font-m': '16px',
  '--sah-font-l': '18px',
  '--sah-font-bold': "'PoppinsMedium', 'Poppins-fallback'",
  '--sah-font-light': "'PoppinsLight', 'Poppins-fallback'"
};

export const StyledContainer = styled(Box)(() => ({
  ...fontNormalizer
}));
