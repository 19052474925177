import { Avatar, Box, Grid, IconButton, Link } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { useState } from 'react';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';

import GroupAdd from '../../../../../../icons/GroupAdd';
import Typography from '../../../../../../components/Typography/';
import HealthAndSafety from '../../../../../../icons/HealthAndSafety';
import Task from '../../../../../../icons/Task';
import PowerRounded from '../../../../../../icons/PowerRounded';

import {
  getLink,
  LINK_PRIVACY_POLICIES,
  LINK_TERMS_AND_CONDITIONS
} from '../../../../../../utils/url';

import SettingIcon from '../../../../../../icons/SettingIcon';

import HelpIcon from '../../../../../../icons/HelpIcon';

import {
  StyledDrawer,
  StyledMenuList,
  StyledMenuItem,
  StyledToolbar,
  StyledHr
} from './styles';

const MobileAvatarMenu = ({ locales, handleLogout, trans, userName }) => {
  const [avatarMenuIsOpen, setAvatarMenuIsOpen] = useState(false);
  const muiTheme = useTheme();

  const handleAvatarMenuClick = () => {
    setAvatarMenuIsOpen(!avatarMenuIsOpen);
  };

  return (
    <Box sx={{ flexGrow: 0, display: { md: 'flex' } }}>
      <IconButton
        sx={{
          width: '100%',
          paddingLeft: '0',
          paddingRight: '0',
          '&:hover': {
            cursor: 'pointer',
            backgroundColor: 'transparent'
          }
        }}
        disableRipple
        onClick={handleAvatarMenuClick}
        size="large"
      >
        <Avatar alt={userName} src="/static/images/avatar/2.jpg" />
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            paddingLeft: '16px'
          }}
        >
          <Typography variant="HeadingM">{userName.split(' ')[0]}</Typography>
          <Box
            sx={{
              marginLeft: 'auto'
            }}
          >
            <ChevronRightIcon
              style={{
                fontSize: '24px'
              }}
            />
          </Box>
        </Box>
      </IconButton>

      <StyledDrawer
        anchor={'right'}
        open={avatarMenuIsOpen}
        onClose={() => setAvatarMenuIsOpen(false)}
        theme={muiTheme}
      >
        <StyledToolbar>
          <Grid container alignItems={'center'}>
            <Box
              sx={{
                marginRight: 'auto',
                display: 'flex'
              }}
            >
              <ArrowBackIcon
                onClick={handleAvatarMenuClick}
                style={{
                  fontSize: '24px'
                }}
              />
            </Box>
            <Box
              sx={{
                marginLeft: 'auto',
                marginRight: 'auto'
              }}
            >
              <Typography variant="HeadingS">{userName}</Typography>
            </Box>
            <Box sx={{ marginLeft: 'auto', display: 'flex' }}>
              <CloseIcon
                onClick={handleAvatarMenuClick}
                style={{
                  fontSize: '24px'
                }}
              />
            </Box>
          </Grid>
        </StyledToolbar>
        <Box role="presentation" sx={{ width: '100vw' }}>
          <StyledMenuList>
            <StyledMenuItem component={Link} href="/settings" disableRipple>
              <SettingIcon />
              <Box sx={{ px: 1 }}>
                <Typography variant="HeadingS">
                  {trans('sahc.menu.route.settings')}
                </Typography>
              </Box>
            </StyledMenuItem>
            <StyledMenuItem component={Link} disableRipple href="/my-referrals">
              <GroupAdd />
              <Box sx={{ px: 1 }}>
                <Typography variant="HeadingS">
                  {trans('sahc.header.refer-a-landlord.button')}
                </Typography>
              </Box>
            </StyledMenuItem>
            <StyledMenuItem
              component={Link}
              disableRipple
              href={getLink(LINK_TERMS_AND_CONDITIONS, locales.current)}
            >
              <Task />
              <Box sx={{ px: 1 }}>
                <Typography variant="HeadingS">
                  {trans('sahc.footer.links.terms.and.conditions')}
                </Typography>
              </Box>
            </StyledMenuItem>
            <StyledMenuItem
              component={Link}
              disableRipple
              href={getLink(LINK_PRIVACY_POLICIES, locales.current)}
            >
              <HealthAndSafety />
              <Box sx={{ px: 1 }}>
                <Typography variant="HeadingS">
                  {trans('sahc.footer.links.privacy.policy')}
                </Typography>
              </Box>
            </StyledMenuItem>
            <StyledMenuItem component={Link} href="/support" disableRipple>
              <HelpIcon />
              <Box sx={{ px: 1 }}>
                <Typography variant="HeadingS">
                  {trans('landlord.support.title')}
                </Typography>
              </Box>
            </StyledMenuItem>
            <StyledHr />
            <StyledMenuItem
              component={Link}
              disableRipple
              onClick={handleLogout}
            >
              <PowerRounded />
              <Box sx={{ px: 1 }}>
                <Typography variant="HeadingS">Logout</Typography>
              </Box>
            </StyledMenuItem>
          </StyledMenuList>
        </Box>
      </StyledDrawer>
    </Box>
  );
};

MobileAvatarMenu.propTypes = {
  locales: PropTypes.object.isRequired,
  handleLogout: PropTypes.func.isRequired,
  trans: PropTypes.func.isRequired,
  userName: PropTypes.string.isRequired
};

export default MobileAvatarMenu;
