import PropTypes from 'prop-types';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { styled } from '@mui/material/styles';
import IconMore from '@mui/icons-material/Add';
import IconLess from '@mui/icons-material/Minimize';

const StyledAccordion = styled(Accordion)({
  padding: '8px 0 16px',
  borderBottom: '1px solid var(--sah-dark)',
  whiteSpace: 'pre-line',
  backgroundColor: 'inherit',
  boxShadow: 'none'
});

const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  fontFamily: 'var(--sah-font-bold)',
  fontSize: 'var(--sah-font-l)',
  lineHeight: 'normal',
  color: 'var(--sah-interaction-dark)',
  padding: '16px 0',
  [theme.breakpoints.down('md')]: {
    fontSize: 'var(--sah-font-m)',
    lineHeight: 'var(--sah-line-height-m)'
  }
}));

const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  fontSize: 'var(--sah-font-m)',
  lineHeight: '24px',
  color: 'var(--sah-dark)',
  padding: '0',
  marginBottom: '20px',
  [theme.breakpoints.down('md')]: {
    fontSize: 'var(--sah-font-s)',
    lineHeight: 'var(--sah-line-height-s)'
  },
  '& ul': {
    margin: 0
  },
  '& a': {
    textDecoration: 'underline',
    color: 'var(--sah-interaction-dark)'
  }
}));

const IconWrapper = styled('span')({
  color: 'var(--sah-dark)'
});

const FAQAccordionItem = ({
  id,
  top,
  content,
  isExpanded = false,
  onCollapsedChange = () => {}
}) => {
  return (
    <StyledAccordion expanded={isExpanded} onChange={onCollapsedChange}>
      <StyledAccordionSummary
        expandIcon={
          <IconWrapper>{isExpanded ? <IconLess /> : <IconMore />}</IconWrapper>
        }
        aria-controls={id}
        id={id}
      >
        {top}
      </StyledAccordionSummary>
      <StyledAccordionDetails>
        {/* eslint-disable-next-line react/no-danger */}
        <div dangerouslySetInnerHTML={{ __html: content }} />
      </StyledAccordionDetails>
    </StyledAccordion>
  );
};

FAQAccordionItem.propTypes = {
  id: PropTypes.string.isRequired,
  top: PropTypes.node.isRequired,
  content: PropTypes.node.isRequired,
  isExpanded: PropTypes.bool,
  onCollapsedChange: PropTypes.func
};

export default FAQAccordionItem;
