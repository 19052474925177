import { PropTypes } from 'prop-types';

import { getPagePath } from '@spotahome/soyuz/client';

import { StyledButton } from './styles';

const LandingButton = ({
  children,
  href = null,
  onClick = () => null,
  dataTest = ''
}) => (
  <StyledButton
    data-test={dataTest}
    size="big"
    color="primary"
    href={href || getPagePath('landlord.signup')}
    onClick={onClick}
  >
    {children}
  </StyledButton>
);

LandingButton.propTypes = {
  dataTest: PropTypes.string,
  href: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.node.isRequired
};

export default LandingButton;
