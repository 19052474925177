import { styled } from '@mui/material/styles';

export const StyledPlansSection = styled('section')(({ theme }) => ({
  padding: '0',
  background: 'var(sah--white)',
  marginBottom: 0,
  [theme.breakpoints.up('sm')]: {
    marginBottom: 120
  }
}));
