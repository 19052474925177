import { useState, useRef } from 'react';
import {
  Box,
  Paper,
  DialogContent,
  DialogTitle,
  MenuList,
  ClickAwayListener
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import PropTypes from 'prop-types';

import Typography from '../../../../../../components/Typography/';

import Es from '../../../../../../icons/es';
import En from '../../../../../../icons/en';
import It from '../../../../../../icons/it';
import De from '../../../../../../icons/de';
import Fr from '../../../../../../icons/fr';
import Pt from '../../../../../../icons/pt';

import { Popper, MenuItem, Arrow, AvatarIconButton } from './styles';

const flags = {
  es: <Es />,
  en: <En />,
  it: <It />,
  de: <De />,
  fr: <Fr />,
  pt: <Pt />
};

const LanguageMenu = ({ locales, onLanguageChange }) => {
  const [arrowRef, setArrowRef] = useState(null);
  const [open, setOpen] = useState(false);
  const [lang, setLang] = useState(locales.current);
  const anchorRef = useRef(null);

  const handleClickButton = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClickAway = () => {
    if (open) {
      setOpen(false);
    }
  };

  const handleLanguageChange = locale => {
    setLang(locale);
    onLanguageChange(locale);
    setOpen(false);
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box sx={{ flexGrow: 0, display: { md: 'flex' } }}>
        <AvatarIconButton
          data-test="change-language"
          onClick={handleClickButton}
          disableRipple
          size="large"
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              paddingLeft: '6px',
              textTransform: 'capitalize'
            }}
          >
            <Box sx={{ display: 'flex' }} ref={anchorRef}>
              <Typography variant="HeadingS">{lang}</Typography>
            </Box>
            <KeyboardArrowDownIcon
              style={{
                transform: open ? 'rotate(180deg)' : 'rotate(0deg)',
                transition: 'transform 0.2s ease-in-out'
              }}
            />
          </Box>
        </AvatarIconButton>
        <Popper
          placement="bottom-end"
          anchorEl={anchorRef.current}
          disablePortal={false}
          open={open}
          modifiers={[
            {
              name: 'flip',
              enabled: true
            },
            {
              name: 'preventOverflow',
              enabled: true,
              options: {
                boundariesElement: 'scrollParent'
              }
            },
            {
              name: 'arrow',
              enabled: true,
              options: {
                element: arrowRef
              }
            }
          ]}
        >
          <Arrow ref={setArrowRef} />

          <Paper
            sx={{
              width: '260px',
              maxWidth: 400,
              overflow: 'auto',
              padding: '0',
              borderRadius: '16px'
            }}
          >
            <DialogTitle
              sx={{
                padding: '24px'
              }}
            >
              <Typography variant="HeadingM">Language</Typography>
            </DialogTitle>
            <DialogContent
              sx={{
                padding: '0px'
              }}
            >
              <MenuList
                sx={{
                  padding: '0px'
                }}
              >
                {Object.keys(locales.ui).map(locale => (
                  <MenuItem
                    data-test={`change-language-${locale}`}
                    disableRipple
                    onClick={() => handleLanguageChange(locale)}
                    style={{
                      backgroundColor: locale === lang ? '#f5f5f5' : 'white'
                    }}
                    key={locales.ui[locale].name}
                  >
                    <Box
                      sx={{
                        paddingRight: '12px'
                      }}
                    >
                      {flags[locale]}
                    </Box>
                    <Typography variant="ParagraphSShort">
                      {locales.ui[locale].name}
                    </Typography>
                  </MenuItem>
                ))}
              </MenuList>
            </DialogContent>
          </Paper>
        </Popper>
      </Box>
    </ClickAwayListener>
  );
};

LanguageMenu.propTypes = {
  locales: PropTypes.object.isRequired,
  onLanguageChange: PropTypes.func.isRequired
};

export default LanguageMenu;
