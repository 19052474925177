import Proptypes from 'prop-types';

import DefaultButton from '../../../../components/DefaultButton';
import PrimaryButton from '../../../../components/PrimaryButton';
import { PLANS } from '../../plansInfo';

import TableButton from './styles';

const ActionButton = ({ plan, currentPlan, fullWidth = false, ...props }) => {
  const isUpgradeToPlusAvailable =
    currentPlan === PLANS.STANDARD && plan === PLANS.PLUS;
  const ButtonTag = isUpgradeToPlusAvailable ? PrimaryButton : DefaultButton;

  return (
    <TableButton
      {...props}
      component={ButtonTag}
      plus={isUpgradeToPlusAvailable}
      fullWidth={fullWidth}
    />
  );
};

ActionButton.propTypes = {
  plan: Proptypes.string.isRequired,
  children: Proptypes.node.isRequired,
  currentPlan: Proptypes.string.isRequired,
  fullWidth: Proptypes.bool
};

export default ActionButton;
