import { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { getPagePath, useSoyuzLocales } from '@spotahome/soyuz/client';
import {
  LanguageSwitcher,
  TrustpilotReviews,
  FacebookIcon,
  Instagram as InstagramIcon,
  TwitterIcon,
  Link
} from '@spotahome/ui-library';
import Tracking from '@spotahome/soyuz-tracking';

import { useLocaleChange } from '../../hooks/useLocaleChange';

import { trackLandlordLandingSectionViewed } from '../../utils/track';

import LinkedInIcon from './icons/LinkedIn';
import SpotahomeIcon from './icons/SpotahomeIcon';

import FooterBlockList from './FooterBlockList';
import HelpCenterButton from './HelpCenterButton';
import SocialLink from './SocialLink';

import {
  StyledFooter,
  StyledSectionWrapper,
  StyledAppFooter,
  StyledDisclaimer,
  StyledCopyright,
  StyledSocial
} from './styles';

const getYear = () => new Date().getFullYear();

const trackOpenAppLink = ({ eventAction }) => {
  Tracking.ga.sendEvent('app-promotion', eventAction, 'open');
};

const getLinks = ({ locale }) => ({
  legal: [
    {
      url: getPagePath('externals.privacy-policies', { locale }),
      locoKey: 'footer.item.privacy_policies',
      noFollow: true
    },
    {
      url: getPagePath('externals.cookiesPolicy', { locale }),
      locoKey: 'footer.item.cookies_policy',
      noFollow: true
    },
    {
      url: getPagePath('externals.terms-and-conditions', { locale }),
      locoKey: 'footer.item.terms_and_conditions'
    }
  ],
  support: [
    {
      url: getPagePath('externals.help-center', { locale }),
      renderItem: () => (
        <HelpCenterButton
          color="exposed"
          href={getPagePath('externals.help-center')}
        />
      )
    },
    {
      url: getPagePath('externals.contact-us', { locale }),
      locoKey: 'footer.item.contact_support'
    }
  ],
  spotahome: [
    {
      url: getPagePath('externals.how-it-works', { locale }),
      locoKey: 'footer.item.how_it_works'
    },
    {
      url: getPagePath('externals.about-us', { locale }),
      locoKey: 'footer.item.about_us'
    },
    {
      url: getPagePath('externals.blog', { locale }),
      locoKey: 'footer.item.blog'
    }
  ],
  app: [
    {
      url: 'https://www.trustpilot.com/review/www.spotahome.com',
      renderItem: () => (
        <TrustpilotReviews theme="minimal" isExternalLink hideScore />
      )
    },
    {
      imgKey: 'https://static.spotahome.com/login_image/app-store.png',
      url: 'https://spotahome.app.link/ios_footer_home',
      alt: 'App Store',
      onClick: () =>
        trackOpenAppLink({
          eventAction: 'footer-icon-ios'
        })
    },
    {
      imgKey: 'https://static.spotahome.com/login_image/google-play.png',
      url: 'https://spotahome.app.link/android_footer_home',
      alt: 'Google Play Store',
      onClick: () =>
        trackOpenAppLink({
          eventAction: 'footer-icon-android'
        })
    }
  ],
  social: [
    {
      url: getPagePath('externals.social.instagram', { locale }),
      renderItem: () => (
        <Link
          hover
          size="medium"
          href={getPagePath('externals.social.instagram')}
          underlined={false}
        >
          <InstagramIcon />
        </Link>
      )
    },
    {
      url: getPagePath('externals.social.twitter', { locale }),
      renderItem: () => (
        <SocialLink href={getPagePath('externals.social.twitter')}>
          <TwitterIcon />
        </SocialLink>
      )
    },
    {
      url: getPagePath('externals.social.linkedin', { locale }),
      renderItem: () => (
        <SocialLink href={getPagePath('externals.social.linkedin')}>
          <LinkedInIcon />
        </SocialLink>
      )
    },
    {
      url: getPagePath('externals.social.facebook', { locale }),
      renderItem: () => (
        <SocialLink href={getPagePath('externals.social.facebook')}>
          <FacebookIcon size="big" theme="dark" />
        </SocialLink>
      )
    }
  ]
});

const Footer = () => {
  const handleOnLocaleChange = useLocaleChange();
  const { current, routes } = useSoyuzLocales();

  const { legal, support, spotahome, app, social } = getLinks({
    locale: current
  });

  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.5
  });

  useEffect(() => {
    if (inView) {
      trackLandlordLandingSectionViewed('bottom');
    }
  }, [inView]);

  return (
    <div ref={ref}>
      <StyledFooter>
        <StyledSectionWrapper>
          <FooterBlockList title="Spotahome" links={spotahome} />
          <FooterBlockList title="footer.label.support" links={support} />
          <FooterBlockList title="footer.label.legal" links={legal} />
          <StyledAppFooter isInline links={app} />
        </StyledSectionWrapper>
        <StyledSectionWrapper>
          <StyledDisclaimer>
            <hr />
            <StyledCopyright>
              <SpotahomeIcon /> ©{getYear()} Spotahome
            </StyledCopyright>
            <StyledSocial>
              <LanguageSwitcher
                theme="dark"
                onChange={handleOnLocaleChange}
                locales={Object.keys(routes)}
                current={current}
              />
              <FooterBlockList links={social} isInline />
            </StyledSocial>
          </StyledDisclaimer>
        </StyledSectionWrapper>
      </StyledFooter>
    </div>
  );
};

export default Footer;
