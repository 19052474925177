import Tracking from '@spotahome/soyuz-tracking';
import { trans } from '@spotahome/soyuz/client';

import {
  Box,
  TableBody,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';

import Typography from '@spotahome/landlord-panel-ui-library/src/components/Typography';
import ActionButton from '@spotahome/landlord-panel-common/src/components/Plans/components/ActionButton';
import CheckBlack from '@spotahome/landlord-panel-common/src/components/Plans/components/Icons/CheckBlack';
import InfoTooltip from '@spotahome/landlord-panel-ui-library/src/components/InfoTooltip';

import { PlanCard } from './components/PlanCard';
import { plansInfo, tableInfo, tooltips, PLANS } from './plansInfo';
import { Title, Table, StyledTableCell } from './desktopStyles';

const DesktopPlans = () => {
  const handleOnClickPlansButton = (plan, buttonInfo) => {
    Tracking.ga.sendEvent('Landlord-Homepage', 'plans', plan);

    setTimeout(() => {
      window.location.href = buttonInfo.link();
    }, 100);
  };

  const renderRowContent = value => {
    if (typeof value === 'string')
      return <Typography variant="ParagraphXS">{trans(value)}</Typography>;

    if (value) return <CheckBlack />;

    return '-';
  };

  return (
    <>
      <Title>
        <Typography variant="DisplayM">
          {trans('sahc.properties.plans.title')}
        </Typography>
      </Title>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow sx={{ height: 315, position: 'relative' }}>
              <StyledTableCell />
              {Object.entries(plansInfo).map(([plan, planData]) => (
                <StyledTableCell
                  align="center"
                  accent={plan === PLANS.PLUS}
                  sx={{ padding: 0 }}
                  key={plan}
                >
                  <PlanCard
                    plan={plan}
                    planDescription={trans(planData.description)}
                    buttonInfo={planData.buttonInfo}
                  >
                    <ActionButton
                      onClick={() =>
                        handleOnClickPlansButton(plan, planData.buttonInfo)
                      }
                      plan={plan.toLocaleLowerCase()}
                      // Fixed to highlight plus button
                      currentPlan="standard"
                      data-test={`${plan.toLowerCase()}-cta`}
                      fullWidth
                    >
                      <Typography variant="LabelS">
                        {trans(planData.buttonInfo.text)}
                      </Typography>
                    </ActionButton>
                  </PlanCard>
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.entries(tableInfo).map(([key, values]) => (
              <TableRow
                key={key}
                sx={{
                  '&:nth-of-type(odd)': {
                    backgroundColor: '#F7F7F7'
                  }
                }}
              >
                <StyledTableCell
                  component="th"
                  scope="row"
                  sx={{
                    width: '30%'
                  }}
                  colSpan={1}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '20px'
                    }}
                  >
                    <Typography variant="ParagraphXS">{trans(key)}</Typography>
                    {tooltips.includes(key) ? (
                      <InfoTooltip text={`${key}.tooltip`} />
                    ) : null}
                  </Box>
                </StyledTableCell>
                {values.map((value, i) => {
                  const planTypes = Object.values(PLANS);
                  return (
                    <StyledTableCell
                      align="center"
                      accent={i === 1}
                      key={`${key}-${planTypes[i]}`}
                    >
                      {renderRowContent(value)}
                    </StyledTableCell>
                  );
                })}
              </TableRow>
            ))}
            <TableRow>
              <StyledTableCell
                colSpan={4}
                style={{
                  sx: '16px'
                }}
              >
                <Typography variant="ParagraphXS">
                  {trans('sahc.properties.plans.table.asterisk.1')}
                </Typography>
                <Typography variant="ParagraphXS">
                  {trans('sahc.properties.plans.table.asterisk.2')}
                </Typography>
              </StyledTableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default DesktopPlans;
