import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

import FooterBlockList from './FooterBlockList';

export const StyledTopWrapper = styled(Box)(({ theme }) => ({
  textAlign: 'center',
  padding: '64px 16px 0',
  background: 'var(--sah-tertiary)',

  [theme.breakpoints.up('sm')]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  }
}));

export const StyledTitle = styled(Box)(() => ({
  fontFamily: 'var(--sah-font-title)',
  fontWeight: 'var(--sah-font-w-regular)',
  fontSize: 'var(--sah-font-xxxxl)',
  lineHeight: 'var(--sah-line-height-xxxxl)',
  color: 'var(--sah-dark)',
  paddingBottom: '32px'
}));

export const StyledCta = styled(Box)(() => ({
  fontFamily: 'var(--sah-font-bold)',
  padding: '16px 0',
  '& a': {
    padding: '16px 32px'
  }
}));

export const StyledFooter = styled('footer')(() => ({
  paddingTop: '32px',
  color: 'var(--sah-interaction-dark)',
  background: 'var(--sah-tertiary)'
}));

export const StyledSectionWrapper = styled('section')(({ theme }) => ({
  paddingLeft: '16px',
  paddingRight: '16px',
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'wrap'
  },
  [theme.breakpoints.up('lg')]: {
    margin: 'auto',
    maxWidth: '1088px',
    padding: '0 16px'
  }
}));

export const StyledAppFooter = styled(FooterBlockList)(({ theme }) => ({
  marginTop: '12px',
  '& ul': {
    flexWrap: 'wrap',
    [theme.breakpoints.up('sm')]: {
      '& li': {
        order: 1
      },
      '& li:first-child': {
        flexBasis: '100%',
        order: 2
      }
    }
  }
}));

export const StyledDisclaimer = styled(Box)(() => ({
  flex: 1,
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  padding: '32px 0px',
  '& hr': {
    flexBasis: '100%',
    borderTop: '2px solid var(--sah-dark)',
    margin: '32px auto'
  }
}));

export const StyledCopyright = styled(Box)(({ theme }) => ({
  marginBottom: '24px',
  display: 'flex',
  alignItems: 'center',
  paddingBottom: '32px',
  fontFamily: 'var(--sah-font-light)',
  fontWeight: 'var(--sah-font-w-light)',
  fontSize: 'var(--sah-font-s)',
  '& svg': {
    marginRight: '12px'
  },

  [theme.breakpoints.down('md')]: {
    flexBasis: '100%'
  },

  [theme.breakpoints.up('lg')]: {
    marginBottom: 0
  }
}));

export const StyledSocial = styled(Box)(({ theme }) => ({
  textAlign: 'left',
  '& > span': {
    width: '120px',
    paddingRight: '24px'
  },
  '& select': {
    height: 'auto'
  },
  [theme.breakpoints.down('md')]: {
    width: '100%'
  }
}));
