import { trans } from '@spotahome/soyuz/client';
import Typography from '@spotahome/landlord-panel-ui-library/src/components/Typography';

import {
  StyledWrapper,
  StyledContent,
  StyledItem,
  StyledNumber,
  StyledText
} from './styles';

const EXPERIENCE = [
  {
    title: 'landlord.landing.standard.stats.v2.users-per-month.title',
    text: 'landlord.landing.standard.stats2.v2.users-per-month.description.alternative'
  },
  {
    title:
      'landlord.landing.standard.stats.v2.published-properties.title.alternative',
    text: 'landlord.landing.standard.stats.v2.published-properties.description.alternative'
  },
  {
    title: 'landlord.landing.standard.stats.v2.bookings.title',
    text: 'landlord.landing.standard.stats.v2.bookings.description.alternative'
  }
];

const HistoricalMetrics = () => {
  return (
    <StyledWrapper>
      <StyledContent>
        {EXPERIENCE.map(({ title, text }) => (
          <StyledItem key={title}>
            <StyledNumber>
              <Typography variant="DisplayM">{trans(title)}</Typography>
            </StyledNumber>
            <StyledText>
              <Typography variant="TagM">{trans(text)}</Typography>
            </StyledText>
          </StyledItem>
        ))}
      </StyledContent>
    </StyledWrapper>
  );
};

export default HistoricalMetrics;
