import PropTypes from 'prop-types';

import {
  StyledBox,
  StyledIcon,
  StyledTitle,
  StyledDescription,
  StyledBoxWrapper
} from './styles';

const UniqueSellingPoint = ({ renderIcon, description, title }) => (
  <StyledBoxWrapper>
    <StyledBox>
      <StyledIcon>{renderIcon()}</StyledIcon>
      <StyledTitle>{title}</StyledTitle>
      <StyledDescription>{description}</StyledDescription>
    </StyledBox>
  </StyledBoxWrapper>
);

UniqueSellingPoint.propTypes = {
  renderIcon: PropTypes.func.isRequired,
  description: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
};

export default UniqueSellingPoint;
