import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

export const StyledWrapper = styled('section')(({ theme }) => ({
  background: 'var(--sah-secondary)',
  padding: '42px 24px 0',
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
    padding: '42px'
  }
}));

export const StyledContent = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  margin: 'auto',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-evenly',
  maxWidth: '1440px',
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row'
  }
}));

export const StyledItem = styled(Box)(({ theme }) => ({
  textAlign: 'center',
  color: 'var(--sah-dark)',
  padding: '0 12px 0 0',
  [theme.breakpoints.up('md')]: {
    paddingLeft: '12px'
  }
}));

export const StyledNumber = styled(Box)(({ theme }) => ({
  marginBottom: 0,
  [theme.breakpoints.up('md')]: {
    marginBottom: '8px'
  }
}));

export const StyledText = styled(Box)(({ theme }) => ({
  textTransform: 'uppercase',
  marginBottom: '42px',
  [theme.breakpoints.up('md')]: {
    marginBottom: 0
  }
}));
