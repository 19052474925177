import { styled } from '@mui/material/styles';
import { Button } from '@spotahome/ui-library';
import Typography from '@spotahome/landlord-panel-ui-library/src/components/Typography';

export const StyledMain = styled('main')(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  alignContent: 'stretch',
  margin: 0,
  [theme.breakpoints.up('sm')]: {
    margin: '80px 48px'
  }
}));

export const StyledWrapper = styled('section')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: 32,
  width: '100%',
  maxWidth: 590,
  [theme.breakpoints.up('sm')]: {
    justifyContent: 'start',
    width: '50%',
    paddingLeft: 64
  },
  [theme.breakpoints.up('md')]: {
    paddingLeft: 100
  }
}));

export const StyledCtaContent = styled(Typography)(() => ({
  marginBottom: 32
}));

export const StyledCta = styled(Button)(({ theme }) => ({
  fontFamily: 'var(--sah-font-bold) !important',
  fontSize: 'var(--sah-font-m)',
  display: 'inline-block !important',
  margin: 0,
  padding: '16px 32px !important',
  borderRadius: 100,
  minWidth: 150,
  [theme.breakpoints.up('sm')]: {
    borderRadius: '0',
    width: 'auto',
    minWidth: 200
  },
  [theme.breakpoints.up('md')]: {
    minWidth: 320
  }
}));

export const StyledDesktopPicture = styled('picture')(({ theme }) => ({
  display: 'none',
  overflow: 'hidden',
  margin: 0,
  [theme.breakpoints.up('sm')]: {
    display: 'flex',
    justifyContent: 'end',
    width: '50%'
  }
}));

export const StyledMobilePicture = styled('picture')(({ theme }) => ({
  display: 'flex',
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    display: 'none'
  },
  '& > img': {
    maxWidth: 675,
    width: '100%'
  },
  '& > picture': {
    width: '100%'
  }
}));

export const StyledImage = styled('img')(({ theme }) => ({
  objectFit: 'cover',
  borderRadius: 0,
  [theme.breakpoints.up('sm')]: {
    borderRadius: '24px'
  }
}));
