import { Box, Table, TableBody, TableContainer, TableRow } from '@mui/material';
import PropTypes from 'prop-types';

import { trans } from '@spotahome/soyuz/client';
import Tracking from '@spotahome/soyuz-tracking';

import Typography from '@spotahome/landlord-panel-ui-library/src/components/Typography';
import ActionButton from '@spotahome/landlord-panel-common/src/components/Plans/components/ActionButton';
import CheckBlack from '@spotahome/landlord-panel-common/src/components/Plans/components/Icons/CheckBlack';

import { plansInfo, tableInfo, PLANS } from '../plansInfo';

import { PlanCard } from './PlanCard';

import {
  StyledTitle,
  StyledRow,
  StyledCell,
  StyledButtonWrapper
} from './styles';

const PlanContainer = ({ plan }) => {
  const { description, buttonInfo } = plansInfo[plan];

  const renderRowContent = row => {
    if (typeof row === 'string') return trans(row);
    if (row) return <CheckBlack />;

    return '-';
  };

  const handleActionClick = () => {
    Tracking.ga.sendEvent('Landlord-Homepage', 'plans', plan);

    setTimeout(() => {
      window.location.href = buttonInfo.link();
    }, 100);
  };

  return (
    <>
      <Box style={{ width: '100%', marginBottom: 64 }}>
        <PlanCard
          plan={plan}
          planDescription={trans(description)}
          buttonInfo={buttonInfo}
        >
          <ActionButton
            onClick={handleActionClick}
            plan={plan.toLocaleLowerCase()}
            currentPlan="standard"
          >
            <Typography variant="LabelS">{trans(buttonInfo.text)}</Typography>
          </ActionButton>
        </PlanCard>
      </Box>
      <StyledTitle>
        <Typography variant="HeadingL">
          {trans('sahc.properties.plans.table.title', {
            plan: plan.toLowerCase()
          })}
        </Typography>
      </StyledTitle>
      <TableContainer>
        <Table>
          <TableBody>
            {Object.entries(tableInfo).map(row => (
              <StyledRow key={row[0]}>
                <StyledCell scope="row">
                  <Typography variant="ParagraphSTall">
                    {trans(row[0])}
                  </Typography>
                </StyledCell>
                <StyledCell align="right">
                  {renderRowContent(row[1][Object.values(PLANS).indexOf(plan)])}
                </StyledCell>
              </StyledRow>
            ))}
            {plan === PLANS.PLUS || plan === PLANS.ALLIN ? (
              <TableRow>
                <StyledCell
                  colSpan={2}
                  style={{
                    padding: '16px'
                  }}
                >
                  <Typography variant="ParagraphXS">
                    {trans('sahc.properties.plans.table.asterisk.1')}
                  </Typography>
                  <Typography variant="ParagraphXS">
                    {trans('sahc.properties.plans.table.asterisk.2')}
                  </Typography>
                </StyledCell>
              </TableRow>
            ) : null}
          </TableBody>
        </Table>
      </TableContainer>
      <StyledButtonWrapper>
        <ActionButton
          onClick={handleActionClick}
          plan={plan.toLocaleLowerCase()}
          currentPlan="standard"
        >
          <Typography variant="LabelS">{trans(buttonInfo.text)}</Typography>
        </ActionButton>
      </StyledButtonWrapper>
    </>
  );
};

PlanContainer.propTypes = {
  plan: PropTypes.string.isRequired
};

export default PlanContainer;
