import { PropTypes } from 'prop-types';
import { trans, getResourcePath } from '@spotahome/soyuz/client';

import {
  StyledImage,
  StyledMobilePicture,
  StyledDesktopPicture
} from './styles';

const getPicturePath = (pictureId, extension = 'jpg') =>
  getResourcePath(`/images/${pictureId}.${extension}`);

const PlusPicture = ({ pictureId, isMobile = false }) => {
  const PictureComponent = isMobile
    ? StyledMobilePicture
    : StyledDesktopPicture;

  return (
    <PictureComponent>
      <source srcSet={getPicturePath(pictureId, 'webp')} type="image/webp" />
      <StyledImage
        src={getPicturePath(pictureId)}
        loading="lazy"
        alt={trans('landlord.landing.plus-section.title')}
        type="image/jpeg"
      />
    </PictureComponent>
  );
};

PlusPicture.propTypes = {
  isMobile: PropTypes.bool,
  pictureId: PropTypes.string.isRequired
};

export default PlusPicture;
