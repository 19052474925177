import { styled } from '@mui/material/styles';
import { Popover } from '@mui/material';

export const StyledPopover = styled(Popover)(() => ({
  '--sah-font-s': '14px',
  '--sah-font-m': '16px',
  '--sah-font-l': '18px',
  '--sah-font-bold': "'PoppinsMedium', 'Poppins-fallback'",
  '--sah-font-light': "'PoppinsLight', 'Poppins-fallback'"
}));

export const NotificationModalHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '20px 16px',
  borderBottom: '1px solid #E5E5E5',
  [theme.breakpoints.up('sm')]: {
    padding: '2px 16px'
  }
}));

export const NotificationModalBody = styled('div')({
  padding: '32px 16px',
  '& > *:not(:last-child)': {
    marginBottom: '8px'
  }
});
