import { trans } from '@spotahome/soyuz/client';
import Box from '@mui/material/Box';
import { Typography } from '@spotahome/ui-library/base';

import RedesignButton from '../../../../components/RedesignButton/RedesignButton';
import PlaceholderLogo from '../../../../icons/PlaceholderLogo';

import {
  StyledRoot,
  StyledLogo,
  StyledTitle,
  StyledDescription,
  StyledLink
} from './styles';

const ErrorSection = () => {
  return (
    <StyledRoot>
      <StyledLogo>
        <PlaceholderLogo />
      </StyledLogo>
      <StyledTitle>
        <Typography.DisplayM>
          {trans('error-section.title')}
        </Typography.DisplayM>
      </StyledTitle>
      <StyledDescription>
        <Typography.ParagraphMTall>
          {trans('error-section.text')}
        </Typography.ParagraphMTall>
      </StyledDescription>
      <Box>
        <RedesignButton type="primary" size="big" href="/properties">
          <Typography.LabelM>
            {trans('error-section.back-button')}
          </Typography.LabelM>
        </RedesignButton>
        <Typography.LabelS>
          <StyledLink href="/support">
            {trans('error-section.contact-us')}
          </StyledLink>
        </Typography.LabelS>
      </Box>
    </StyledRoot>
  );
};

export default ErrorSection;
