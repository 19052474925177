import { useState, cloneElement } from 'react';
import PropTypes from 'prop-types';

import Collapse from '@mui/material/Collapse';

import Typography from '../../../../../../../components/Typography';

import {
  COLORS,
  ICON_COLOR,
  StyledIconWrapper,
  StyledNotificationWrapper,
  StyledTextWrapper,
  StyledCloseIcon
} from './styles';

const NotificationItem = ({
  color,
  onClose = null,
  notificationIcon = null,
  onClick,
  text
}) => {
  const [open, setOpen] = useState(true);

  const handleOnClose = () => {
    if (onClose) {
      onClose();
    }

    setOpen(false);
  };

  return (
    <>
      <Collapse in={open}>
        <StyledNotificationWrapper
          color={color}
          onClick={onClick}
          role="button"
          tabIndex={0}
        >
          {notificationIcon && (
            <StyledIconWrapper style={{ ...ICON_COLOR[color] }}>
              {cloneElement(notificationIcon)}
            </StyledIconWrapper>
          )}
          <StyledTextWrapper>
            <Typography
              variant="ParagraphSShort"
              color={COLORS[color].color}
              isHTML
            >
              {text}
            </Typography>
          </StyledTextWrapper>

          {onClose && <StyledCloseIcon onClick={handleOnClose} />}
        </StyledNotificationWrapper>
      </Collapse>
    </>
  );
};

NotificationItem.propTypes = {
  color: PropTypes.oneOf(['primary', 'secondary', 'tertiary']).isRequired,
  onClose: PropTypes.func,
  notificationIcon: PropTypes.node,
  onClick: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired
};

export default NotificationItem;
