import { styled } from '@mui/material/styles';
import { Drawer, MenuList, MenuItem, Toolbar } from '@mui/material';

export const StyledDrawer = styled(Drawer)(() => ({}));

export const StyledMenuList = styled(MenuList)(() => ({
  padding: '46px 16px 0px 16px'
}));

export const StyledMenuItem = styled(MenuItem)(() => ({
  padding: '0px 0px 32px 0px',
  '& a, svg': {
    color: '#000000'
  },
  '&:first-of-type': {
    paddingTop: '0'
  },
  '&:last-of-type': {
    paddingTop: '32px'
  },
  '&:hover': {
    backgroundColor: 'transparent'
  }
}));

export const StyledToolbar = styled(Toolbar)(() => ({
  borderBottom: '1px solid #ebebeb'
}));

export const StyledHr = styled('hr')(() => ({
  border: 'none',
  borderTop: '1px solid #EBEBEB'
}));
