import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

export const StyledRoot = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  minHeight: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  padding: 36,
  textAlign: 'center',

  '&.no-icon': {
    marginTop: 0
  }
}));

export const StyledLogo = styled(Box)(() => ({
  marginBottom: 32
}));

export const StyledTitle = styled(Box)(() => ({
  maxWidth: 550,
  marginBottom: 24
}));

export const StyledDescription = styled(Box)(() => ({
  maxWidth: 550,
  marginBottom: 32
}));

export const StyledLink = styled('a')(() => ({
  display: 'block',
  color: 'black',
  marginTop: 24
}));
