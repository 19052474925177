import PropTypes from 'prop-types';

import { StyledRoot, StyledLed } from './styles';

const PrecisionHotspot = ({
  wrapperClassname = undefined,
  ledClassName = undefined,
  position = 'right',
  isHidden = false,
  children
}) => {
  return (
    <StyledRoot className={wrapperClassname} data-testid="precision-hotspot">
      {children}
      <StyledLed
        position={position}
        isHidden={isHidden}
        className={ledClassName}
        component="span"
      />
    </StyledRoot>
  );
};

PrecisionHotspot.propTypes = {
  wrapperClassname: PropTypes.string,
  ledClassName: PropTypes.string,
  position: PropTypes.oneOf(['left', 'right']),
  isHidden: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.array
  ]).isRequired
};

export default PrecisionHotspot;
