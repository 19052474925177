import { styled } from '@mui/material/styles';
import { AppBar, Container, Box } from '@mui/material';

export const StyledAppBar = styled(AppBar)(() => ({
  position: 'relative',
  backgroundColor: 'white',
  borderBottom: '1px solid #ebebeb'
}));

export const StyledContainer = styled(Container)(() => ({
  padding: '0 16px !important',
  '@media (min-width: 600px)': {
    padding: '0 40px !important'
  }
}));

export const StyledLogoContainer = styled(Box)(() => ({
  marginRight: '56px'
}));

export const StyledRightMenu = styled(Box)(() => ({
  display: 'flex',
  flex: '1 1 auto',
  alignItems: 'center',
  justifyContent: 'flex-end'
}));
