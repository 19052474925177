import PropTypes from 'prop-types';
import { Stack, Toolbar, Box } from '@mui/material';
import { Logo } from '@spotahome/ui-library/base';

import { useSoyuzFlag } from '@spotahome/soyuz/client';

import localesShape from '../../../shapes/locales';
import userShape from '../../../../../shapes/user';

import LandingLink from '../../../components/LandingLink';

import {
  StyledAppBar,
  StyledContainer,
  StyledLogoContainer,
  StyledRightMenu
} from './styles';
import AvatarMenu from './AvatarMenu';
import NavMenu from './NavMenu';
import LanguageMenu from './LanguageMenu';
import RegisterMenu from './RegisterMenu';
import NotificationMenu from './NotificationMenu/NotificationMenu';

const NavBar = ({
  trans,
  locales,
  handleLogout,
  onLanguageChange,
  onSectionClick,
  currentRoute = undefined,
  canShowPlansSection = true,
  highlightPlansSection = true,
  hideAllLinks = false,
  user = undefined
}) => {
  const isLoggedIn = !!user?.id;
  const ShowNotificationCenterFlag = useSoyuzFlag(
    'ShowNotificationCenter'
  ).isOn();

  return (
    <StyledAppBar
      position="relative"
      elevation={0}
      color="inherit"
      enableColorOnDark
    >
      <StyledContainer maxWidth={false}>
        <Toolbar disableGutters>
          <Box sx={{ width: '100%' }}>
            <Stack
              direction="row"
              alignItems={'center'}
              sx={{ minHeight: '64px' }}
            >
              <StyledLogoContainer>
                <LandingLink>
                  <Logo />
                </LandingLink>
              </StyledLogoContainer>
              {!hideAllLinks && (
                <NavMenu
                  trans={trans}
                  currentRoute={currentRoute}
                  locales={locales}
                  onSectionClick={onSectionClick}
                  canShowPlansSection={canShowPlansSection}
                  isLoggedIn={isLoggedIn}
                  highlightPlansSection={highlightPlansSection}
                />
              )}
              <StyledRightMenu>
                {isLoggedIn && !hideAllLinks && ShowNotificationCenterFlag && (
                  <NotificationMenu />
                )}
                <LanguageMenu
                  locales={locales}
                  onLanguageChange={onLanguageChange}
                />
                {isLoggedIn && (
                  <AvatarMenu
                    locales={locales}
                    handleLogout={handleLogout}
                    trans={trans}
                    userName={user.fullname}
                  />
                )}
              </StyledRightMenu>
              {!isLoggedIn && <RegisterMenu locale={locales.current} />}
            </Stack>
          </Box>
        </Toolbar>
      </StyledContainer>
    </StyledAppBar>
  );
};

NavBar.propTypes = {
  trans: PropTypes.func.isRequired,
  currentRoute: PropTypes.string,
  locales: localesShape.isRequired,
  handleLogout: PropTypes.func.isRequired,
  user: userShape,
  onLanguageChange: PropTypes.func.isRequired,
  onSectionClick: PropTypes.func.isRequired,
  canShowPlansSection: PropTypes.bool,
  highlightPlansSection: PropTypes.bool,
  hideAllLinks: PropTypes.bool
};

export default NavBar;
