import PropTypes from 'prop-types';

import { trans } from '@spotahome/soyuz/client';
import { Link, Heading } from '@spotahome/ui-library';

import FooterLazyImage from './FooterLazyImage';

import {
  StyledContainer,
  StyledList,
  StyledTitle
} from './FooterBlockList.styles';

const FooterBlockList = ({
  title = null,
  isInline = false,
  className = null,
  links
}) => {
  return (
    <StyledContainer className={className}>
      {title ? (
        <StyledTitle>
          <Heading
            level={2}
            theme="dark"
            size="small-medium"
            type="uppercase"
            align="left"
            text={trans(title)}
          />
        </StyledTitle>
      ) : null}
      <StyledList isInline={isInline}>
        {links.map(
          ({ locoKey, url, imgKey, alt, renderItem, noFollow, onClick }) => (
            <li key={url}>
              {renderItem ? (
                renderItem()
              ) : (
                <Link
                  hover
                  size="medium"
                  href={url}
                  underlined={false}
                  rel={noFollow ? 'nofollow' : ''}
                  onClick={onClick || (() => {})}
                >
                  {imgKey ? (
                    <FooterLazyImage src={imgKey} alt={alt} />
                  ) : (
                    trans(locoKey)
                  )}
                </Link>
              )}
            </li>
          )
        )}
      </StyledList>
    </StyledContainer>
  );
};

FooterBlockList.propTypes = {
  className: PropTypes.string,
  isInline: PropTypes.bool,
  title: PropTypes.string,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      locoKey: PropTypes.string,
      url: PropTypes.string,
      imgKey: PropTypes.string,
      alt: PropTypes.string,
      renderItem: PropTypes.func,
      noFollow: PropTypes.bool
    })
  ).isRequired
};

export default FooterBlockList;
